/* header + footer styling */
header {
  position: relative;
  width: auto;
  height: 223px;
  left: 0px;
  top: 0px;

  background: #6C6969;
  border-bottom: 10px solid #44DFD6;
}

#pro-pic-rect {
  position: absolute;
  width: 165px;
  height: 200px;
  left: 15px;
  top: 8px;

  background: #FEFEFE;
  border: 5px solid #44DFD6;
  border-radius: 15px;

  text-align: center;
}

#pro-pic {
  width: 150px;
  height: 175px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  border-radius: 15px;
}

#header-nav {
  position: absolute;
  width: 561.48px;
  height: 44.3px;
  left: 183px;
  top: 158px;
}

.header-link {
  margin-left: 20px;
  color: #FEFEFE;
  font-size: 36px;
  line-height: 58px;
}

#sidebar {
  float: left;
  width: 100px;
}

.icon {
  width: 56px;
  height: 54px;
}

.twitter {
  height: 45px;
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer-link {
  color: #6C6969;
  font-size: 8px;
}

.side-link {
  margin-left: 20px;
}
