.project-card {
  display: flex;
  height: 225px;
}

.project-preview-card-img {
  flex: none;
  position: relative;
  float: left;
  border: 0;
  border-right: 5px solid #44DFD6;
  border-radius: 0;
  margin: 0 10px 0 0;
  width: 200px;
  height: 100%;

  text-align: center;
}

.overlay-content {
  border-radius: 15px;
  max-width: 100%;
  height: 100%;
}

.project-preview-img {
  margin-top: 10px;
  object-fit: cover;
  max-width: 100%;
}

.project-overlay {
  background: rgba(0,0,0,0.7);
  border-radius: 15px;
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.overlay-content:hover .project-overlay {
  opacity: 1;
}

.overlay-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.overlay-content:hover .overlay-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.overlay-details h3{
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.project-card-content {
  position: relative;
  margin-top: 10px;
  overflow-y: scroll;
}
