html, body {
  margin: 0px;
  height: 100%;
  min-width: 810px;
  overflow: auto;

  font-family: 'Lato', sans-serif;
  font-style: normal;
}

/* general text */
p {
  margin: 10px;
}

.link {
  text-decoration: none;
  color: #44DFD6;
  font-weight: bold;
}

.page-heading {
  width: auto;
  margin-left: 97.5px;
  margin-top: 20px;
  margin-right: 25px;

  border-bottom: 5px solid #44DFD6;
}

small {
  display: block;
  margin-left: 10px;
  font-style: italic;
  color: #6C6969;
}

button {
  margin: 5px;

  font-weight: bold;

  background: none;
  border: 1px solid #44DFD6;
  border-radius: 15px;
}

.hidden {
  display: none;
}

/*general body + card styling*/

.app-container {
  min-height: 100vh;
  display: block;
  position: relative;
  padding-bottom: 50px;
}

.app-body {
  position: relative;
}

.card {
  width: auto;
  height: 200px;
  margin-left: 97.5px;
  margin-top: 20px;
  margin-right: 25px;
  overflow: scroll;

  border: 5px solid #44DFD6;
  border-radius: 15px;
}

.card-heading {
  position: relative;
  margin-left: 10px;

  text-decoration: underline #44DFD6 solid ;
}

.card-content {
  position: relative;
  top: 25%;
  transform: translateY(-50%);
}

/* form formatting */
.form-card {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: auto;
}

input, textarea {
  margin-left: 10px;
  width: 90%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

label {
  margin-left: 10px;
  padding: 12px 12px 12px 0;
  display: inline-block;
}
